<template>
  <div class="hello" style="padding-bottom: 80px">
    <div class="bg"></div>
    <div class="cons">
      <template v-if="hasLoad">
        <van-sticky style="background-color: #fff">
          <!-- <van-nav-bar style="background: #1989fa; color: #ffffff">
        <template #title>
          <span style="color: #ffffff">{{ page_title }}</span>
        </template>
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
      </van-nav-bar> -->
          <div
            class="sak"
            style="
              display: flex;
              justify-content: space-between;
              background-color: #fff;
            "
          >
            <span
              style="font-size: 1.4rem; line-height: 4rem; text-align: center"
              @click="showDateBox"
              ><van-icon
                name="calendar-o"
                style="margin: 0 5px; color: #2c92ff"
              />
              {{ tmpData.date }}</span
            >
            <van-dropdown-menu swipe-threshold="2" style="width: 65vw">
              <van-dropdown-item
                v-model="tmpData.line_id"
                @change="changeLine"
                :options="boatLineArr"
              >
                <template #title> {{ tmpData.line_title }} </template>
              </van-dropdown-item>
              <van-dropdown-item
                v-model="tmpData.type_id"
                @change="changeType"
                :options="typeArr"
              >
                <template #title>{{ tmpData.type_title }}</template>
              </van-dropdown-item>
            </van-dropdown-menu>
          </div>
        </van-sticky>

        <div v-if="hasLoad && dataList.length">
          <div
            class="hot_line"
            @click="goUrl(item.url_line)"
            v-for="(item, idx) in dataList"
            :key="idx"
          >
            <van-image
              fit="cover"
              class="thumb"
              position="center"
              :src="
                item.thumb + '?x-oss-process=image/resize,m_mfit,w_200,h_140'
              "
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <div class="btitle">{{ item.btitle }}</div>
            <div class="content">
              <div class="title">
                <van-text-ellipsis rows="2" :content="item.title" />
              </div>
              <div class="cansu" v-if="item.cansu.length">
                <template v-for="(cs, idx) in item.cansu">
                  <span :key="idx" v-if="idx < 4"
                    >{{ cs.title }}:{{ cs.value ? cs.value : "-" }}</span
                  >
                </template>
              </div>
              <div
                v-if="item.tags && item.tags.length"
                style="height: 20px; line-height: 20px; overflow: hidden"
              >
                <van-tag
                  v-for="(t, i) in item.tags"
                  :key="i"
                  type="success"
                  plain
                  style="margin-right: 4px"
                  >{{ t }}</van-tag
                >
              </div>
              <div class="jindian">
                <van-text-ellipsis rows="1" :content="item.jd" />
              </div>
              <div class="price_info">
                <my-price
                  :price="item.mins"
                  size="large"
                  before="￥"
                  after="起"
                />
              </div>
              <div class="btn">立即预订</div>
            </div>
          </div>
        </div>
        <van-empty v-else image="network" description="没有出行计划" />
      </template>
      <van-calendar
        v-model:show="showdate"
        :show-confirm="false"
        @confirm="onConfirm"
        switch-mode="month"
        style="height: 520px"
      />
    </div>
    <my-footer
      :view="hasLoad"
      activeStr="shipMent"
      :showElse="false"
      :showTabBar="true"
    ></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
<script>
import { ref } from "vue";
import myPrice from "@/components/myPrice.vue";
import ShareBox from "@/components/ShareBox.vue";

import {
  showNotify,
  showToast,
  BarrageProps,
  BarrageItem,
  BarrageInstance,
} from "vant";
import MyBox from "@/components/MyBox.vue";
import MyFooter from "@/components/myFooter.vue";

export default {
  name: "makeOrder",
  components: {
    MyBox,
    MyFooter,
    myPrice,
    ShareBox,
  },
  data() {
    return {
      hasLoad: false,
      loading: false,
      tmpData: false,
      nowdate: "",
      page_title: "船期表",
      matouArr: [], //码头数组
      boatLineArr: [],
      typeArr: [],
      dataList: [],
      showdate: false,
      today: {
        date: "",
        week: "",
      },
      loadShare: true,
      shareData: {
        title: "三峡游轮售票中心-船期表",
        desc: "三峡游轮船期表",
        // imgUrl:process.env.VUE_APP_HOST +"/oss/2024-09-07/3aed55e212317f6661b22d196be03139.jpg?x-oss-process=image/resize,m_mfit,w_120,h_120",
        link: false,
      },
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return { onClickLeft };
  },
  mounted() {
    // this.matouArr = localStorage.getItem("matouArr")
    //   ? JSON.parse(localStorage.getItem("matouArr"))
    //   : [];

    let qe =
      this.$route.query.q != null && this.$route.query.q
        ? this.$route.query.q
        : false;
    if (qe == "quick") {
      this.tmpData = localStorage.getItem("searchData")
        ? JSON.parse(localStorage.getItem("searchData"))
        : false;
      let _this = this;
      if (!this.tmpData) {
        showToast("参数错误");
        setTimeout(function () {
          _this.$router.go(-1);
        }, 1000);
        return;
      }
    } else {
      let date = new Date();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      let mdate =
        date.getFullYear() +
        "-" +
        (m >= 10 ? m : "0" + m) +
        "-" +
        (d >= 10 ? d : "0" + d);
      this.tmpData = {
        st: "day",
        down_id: 0,
        down_title: "全部",
        up_id: 0,
        up_title: "全部",
        line_id: 0,
        line_title: "全部航线",
        type_id: 0,
        type_title: "全部游轮",
        date: mdate,
      };
    }
    // // 加减数量
    // this.tmpData.ns = 0;
    // this.page_title = `${this.tmpData.up.title}->${this.tmpData.down.title}`;

    let arr = localStorage.getItem("boatLineArr")
      ? JSON.parse(localStorage.getItem("boatLineArr"))
      : [];
    this.boatLineArr.push({
      value: 0,
      text: "全部航线",
      up_id: 0,
      down_id: 0,
    });
    arr.map((item) => {
      this.boatLineArr.push({
        value: item.id,
        text: item.title,
        up_id: item.up_id,
        down_id: item.down_id,
      });
    });

    // 游船系列
    let typearr = localStorage.getItem("typeArr")
      ? JSON.parse(localStorage.getItem("typeArr"))
      : [];
    this.typeArr.push({
      value: 0,
      text: "全部游轮",
    });
    typearr.map((item) => {
      this.typeArr.push({
        value: item.id,
        text: item.title,
      });
    });

    this.getList();
  },
  methods: {
    prveDay() {
      this.tmpData.ns -= 1;
      this.getList();
    },
    nextDay() {
      this.tmpData.ns += 1;
      this.getList();
    },
    showDateBox() {
      this.showdate = true;
    },
    onConfirm(date) {
      let m = date.getMonth() + 1;
      let d = date.getDate();
      this.tmpData.ns = 0;
      this.tmpData.date = `${date.getFullYear()}-${m < 10 ? "0" + m : m}-${
        d < 10 ? "0" + d : d
      }`;
      localStorage.setItem("searchData", JSON.stringify(this.tmpData));
      this.showdate = false;
      this.getList();
    },
    changeLine(val) {
      this.tmpData.line_id = val;
      this.boatLineArr.map((item) => {
        if (item.value == val) {
          this.tmpData.line_title = item.text;
          this.tmpData.up_id = item.up_id;
          this.tmpData.down_id = item.down_id;
        }
      });
      this.getList();
    },
    changeType(val) {
      this.tmpData.type_id = val;
      this.typeArr.map((item) => {
        if (item.value == val) {
          this.tmpData.type_title = item.text;
        }
      });
      this.getList();
    },
    changeMatou() {
      let a = this.tmpData.up;
      let b = this.tmpData.down;
      this.tmpData.up = b;
      this.tmpData.down = a;
      localStorage.setItem("searchData", JSON.stringify(this.tmpData));
      this.page_title = `${this.tmpData.up.title}->${this.tmpData.down.title}`;
      this.getList();
    },
    getList() {
      this.loading = true;
      let form = {
        up_id: this.tmpData.up_id,
        down_id: this.tmpData.down_id,
        type_id: this.tmpData.type_id,
        st: this.tmpData.st, // month day boat line 查询类型
        date: this.tmpData.date,
        ns: this.tmpData.ns,
      };
      this.$http
        .postData("/Index/getShipMent", { ...form })
        .then((res) => {
          this.loading = false;
          this.hasLoad = true;
          this.dataList = res.list;
          this.today = res.today;
          this.tmpData.date = res.today.date;
          this.tmpData.ns = 0;
          localStorage.setItem("searchData", JSON.stringify(this.tmpData));
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    goUrl(url) {
      if (!url) return;
      if (url == "not") {
        this.$notFound();
        return;
      }
      this.$router.push(url);
    },
  },
};
</script>
  <style scoped>
.hello {
  position: relative;
}
.filter {
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
}
.hot_line {
  background: #ffffff;
  border-radius: 4px;
  margin: 10px;
  font-size: 12px;
  min-height: 154px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}
.hot_line .thumb {
  width: 130px;
  height: 130px;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
}
.hot_line .btitle {
  width: 130px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  left: 10px;
  bottom: 14px;
  border-radius: 0 0 6px 6px;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.hot_line .content {
  margin: 10px;
  margin-left: 150px;
}
.hot_line .content .title {
  font-size: 1.2rem;
  color: #2c92ff;
  height: 2.4rem;
  line-height: 2.4rem;
  overflow: hidden;
  padding-top: 6px;
}
.hot_line .content .cansu span {
  display: inline-block;
  width: 50%;
  line-height: 1.6rem !important;
  color: #666666;
}
.hot_line .jindian {
  color: #999999;
  padding: 5px 0;
}

.hot_line .btn {
  border-radius: 5px;
  background: #ff8800;
  color: #ffffff;
  font-size: 1rem;
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  padding: 0 10px;
  line-height: 2rem;
}
.price_info {
  position: absolute;
  z-index: 1;
  left: 150px;
  bottom: 10px;
}
.hot_line .jt {
  border-bottom: 1px solid #b0b0b0;
  position: absolute;
  left: 34%;
  right: 34%;
  top: 32px;
  display: flex;
  justify-content: right;
}
.hot_line .jt .arrow {
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 0px 10px;
  border-color: transparent transparent transparent #2c92ff;
  /* transform: rotate(45deg); */
}
.sak {
  box-shadow: 0 2px 12px rgba(100, 101, 102, 0.12);
}
.bg {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("/public/img/login_bg.jpg");
  background-position: top center;
  background-size: 100% 100%;
  min-height: 100vh;
  opacity: 0.2;
}
.cons {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  padding-bottom: 90px;
}
</style>
<style>
.van-dropdown-menu__bar {
  box-shadow: none !important;
}
</style>
  